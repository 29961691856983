import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getSmartScanCaseReport_Ajax } from "../../helpers/requests";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import {
  handleApiErrors,
  openUrlInNewTab,
  getSeeADentistNowButtonText,
  onImageError,
  getDiscountString
} from "../../helpers/utils";
import DentalConditionDetailsAccordion from "./DentalConditionDetailsAccordion";
import SmartScanGraph from "../SmartScanGraph/SmartScanGraph";
import AetnaDarkLogo from "../../assets/images/aetna-dark-logo.png";
import PriceTagIcon from "../../assets/images/price-tagIcon.png";
import { SmartScanGraphDataPropertyIndices } from "../../constants";
import useDisplaySmartScanRecommendedProduct from "../CustomHooks/useDisplaySmartScanRecommendedProduct";
import ChatCard from "../Chat/ChatCard";
import WhitenessScorePage from "./WhitenessScorePage";

const WellnessScorePage = (props) => {
  const { smartScanId, patientId } = props;
  const dispatch = useDispatch();
  const [smartScanCaseReport, setSmartScanCaseReport] = useState(null);
  const [smartScanLatestId, setSmartScanLatestId] = useState(0);
  const [productPromoCodes, setProductPromoCodes] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPatient = useSelector((state) => state.userData.currentPatient);
  const isOnDemandCallFeatureEnabled = useSelector(
    (state) => state?.appSettings?.settingsData?.isOnDemandCallFeatureEnabled
  );
  const shouldShowWhitnessReport = useSelector(
    (state) => state?.appSettings?.settingsData?.shouldShowWhitnessReport
  );
  const {
    shouldShowRecommendedProductsSection,
    renderProducts,
    shouldShowRecommendedProductsInSmartScan,
    renderIssues,
    copyPromoCode
  } = useDisplaySmartScanRecommendedProduct(smartScanCaseReport);

  const onDemandCampaignCharge = useSelector((state) => {
    if (state?.userData?.onDemandCampaignCharge > 0) {
      return state?.userData?.onDemandCampaignCharge;
    } else if (location.state?.userData?.onDemandCampaignCharge > 0) {
      return location.state?.userData?.onDemandCampaignCharge;
    }

    return 0;
  });

  const isCICIChatEnabled = useSelector((state) => {
    return state?.appSettings?.settingsData?.isCICIChatEnabled;
  });

  useEffect(() => {
    getSmartScanCaseReport(smartScanId);
  }, [smartScanId]);

  useEffect(() => {
    if (currentPatient) {
      setSmartScanCaseReport(null);
      getSmartScanCaseReport(smartScanId);
    }
  }, [currentPatient]);

  function getSmartScanCaseReport(smartScanId) {
    let params = {
      includeDetails: true,
      patId: patientId,
      shouldGetLatestSmartScanCaseReport: smartScanId > 0 ? false : true,
      includeRecommendedProducts: shouldShowRecommendedProductsInSmartScan
    };

    dispatch(showLoadingSpinner());
    getSmartScanCaseReport_Ajax(
      smartScanId,
      params,
      function (resposne) {
        dispatch(hideLoadingSpinner());

        if (
          resposne &&
          resposne?.success &&
          resposne?.data?.smartScanCaseReportVM
        ) {
          setSmartScanCaseReport(resposne.data.smartScanCaseReportVM);
          setSmartScanLatestId(resposne.data.smartScanCaseReportVM.smartScanId);
          setProductPromoCodes(resposne.data.smartScanCaseReportVM.promoCodes);
        }
      },
      function (err) {
        dispatch(hideLoadingSpinner());
        handleApiErrors(err);
      }
    );
  }

  function renderSmartScanChart() {
    return patientId > 0 ? (
      <SmartScanGraph
        patientId={patientId}
        onGraphPointClicked={onGraphPointClicked}
        smartScanId={getSmartScanId()}
      />
    ) : (
      <></>
    );
  }

  function onGraphPointClicked(smartScan) {
    if (
      smartScan &&
      smartScan[SmartScanGraphDataPropertyIndices.SmartScanId] > 0
    ) {
      getSmartScanCaseReport(
        smartScan[SmartScanGraphDataPropertyIndices.SmartScanId]
      );
    }
  }

  function getSmartScanId() {
    return smartScanId > 0 ? smartScanId : smartScanLatestId;
  }

  function onSeeADentistNowButtonClicked() {
    if ((smartScanId > 0 || smartScanLatestId > 0) && patientId > 0) {
      navigate("/see-dentist", {
        state: {
          smartScanId: getSmartScanId(),
          patientId
        }
      });
    }
  }

  function onPrintBtnClicked() {
    if (smartScanCaseReport?.smartScanId > 0) {
      let url =
        "/wellness-score-print/" +
        smartScanCaseReport?.smartScanId +
        "?patid=" +
        patientId;
      openUrlInNewTab(url);
    }
  }

  const [activeTab, setActiveTab] = useState("Wellness Score");

  const tabs = [{ label: "Wellness Score" }];

  if (shouldShowWhitnessReport) {
    tabs.push({ label: "Whiteness Score" });
  }

  const handleTabClick = (label) => {
    setActiveTab(label);
  };

  return (
    <>
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Wellness Score
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h1 className="mb-4">Smart Scan</h1>
            <div style={{ display: "flex" }}>
              {tabs.map((tab) => (
                <button
                  className="smartscan-tab-button"
                  key={tab.label}
                  style={{
                    padding: "15px 30px",
                    backgroundColor:
                      tab.label === activeTab ? "#fff" : "#00000000",
                    color: tab.label === activeTab ? "#333" : "#605fa4",
                    border: "1px solid transparent",
                    marginRight: "5px",
                    borderColor: tab.label === activeTab ? "#ffffff" : "#605fa4"
                  }}
                  onClick={() => handleTabClick(tab.label)}
                >
                  {tab.label}
                </button>
              ))}
            </div>

            <div>
              {activeTab === "Wellness Score" && (
                <>
                  {" "}
                  {renderSmartScanChart()}
                  <div className="collapse show" id="collapseSmartScan">
                    <div
                      className="accordion wellness-score-accordion wellness-accordion1"
                      id="accordionSmartScan"
                    >
                      {smartScanCaseReport?.smartScanCaseReportId > 0 ? (
                        <>
                          <DentalConditionDetailsAccordion
                            title={"Cavities"}
                            dentalConditionData={smartScanCaseReport?.cavity}
                            onDemandCampaignCharge={onDemandCampaignCharge}
                            onSeeADentistNowButtonClicked={
                              onSeeADentistNowButtonClicked
                            }
                          />

                          <DentalConditionDetailsAccordion
                            title={"Defective Restoration"}
                            dentalConditionData={
                              smartScanCaseReport?.defectiveRestoration
                            }
                            onDemandCampaignCharge={onDemandCampaignCharge}
                            onSeeADentistNowButtonClicked={
                              onSeeADentistNowButtonClicked
                            }
                          />

                          <DentalConditionDetailsAccordion
                            title={"Broken Tooth"}
                            dentalConditionData={
                              smartScanCaseReport?.brokenTooth
                            }
                            onDemandCampaignCharge={onDemandCampaignCharge}
                            onSeeADentistNowButtonClicked={
                              onSeeADentistNowButtonClicked
                            }
                          />

                          <DentalConditionDetailsAccordion
                            title={"Plaque Buildup"}
                            dentalConditionData={
                              smartScanCaseReport?.plaqueBuildUp
                            }
                            onDemandCampaignCharge={onDemandCampaignCharge}
                            onSeeADentistNowButtonClicked={
                              onSeeADentistNowButtonClicked
                            }
                          />

                          <DentalConditionDetailsAccordion
                            title={"Gum Inflammation"}
                            dentalConditionData={
                              smartScanCaseReport?.gumInflammation
                            }
                            onDemandCampaignCharge={onDemandCampaignCharge}
                            onSeeADentistNowButtonClicked={
                              onSeeADentistNowButtonClicked
                            }
                          />
                          {isCICIChatEnabled ? (
                            <ChatCard
                              smartScanId={smartScanLatestId}
                              patientId={patientId}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {shouldShowRecommendedProductsSection() ? (
                    <>
                      <div className="my-4">
                        <div
                          style={{
                            background:
                              "linear-gradient(135deg, #54b7e8 0%, #7846b9 100%)",
                            borderRadius: "1rem",
                            padding: "1.2rem"
                          }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <div className="poor_categories">
                                <h2 className="text-white mb-3">
                                  Let us help get you on the road to better oral
                                  health!
                                </h2>
                                <p className="text-white mb-4">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        smartScanCaseReport?.recommendedCategoryGenericDescription
                                    }}
                                  ></div>
                                </p>
                                {productPromoCodes?.length > 0 &&
                                  productPromoCodes.map((promoCode, index) => (
                                    <div className="member-card row mb-4">
                                      <div className="col-lg-4 d-flex flex-nowrap align-items-center gap-3 mb-3 mb-lg-0">
                                        <img
                                          className="price-tag"
                                          src={PriceTagIcon}
                                          alt=""
                                          onError={onImageError}
                                        ></img>
                                        <p className="fs-4">
                                          Aetna® members get{" "}
                                          {getDiscountString(promoCode)} off CVS
                                          Health® and Listerine® oral health
                                          products
                                        </p>
                                      </div>
                                      <div className="col-lg-5 position-relative text-center mb-3 mb-lg-0">
                                        <div
                                          id={"tooltip_title" + index}
                                          className="tooltip-title"
                                          style={{ display: "none" }}
                                        >
                                          Copied!
                                        </div>
                                        <button
                                          className="btn btn-warning btn-lg text-white"
                                          onClick={() =>
                                            copyPromoCode(
                                              index,
                                              promoCode?.code
                                            )
                                          }
                                        >
                                          Use code{" "}
                                          <span className="fw-bold">
                                            {promoCode.code}
                                          </span>{" "}
                                          at checkout!
                                        </button>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="d-flex align-items-center justify-content-center justify-content-lg-end gap-3 ms-lg-auto">
                                          <img
                                            src={AetnaDarkLogo}
                                            alt=""
                                            onError={onImageError}
                                          ></img>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                {renderIssues(true)}
                                <div className="tabs_column">
                                  <div
                                    className="accordion"
                                    id="accordionExample"
                                  >
                                    {renderProducts()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {smartScanCaseReport?.smartScanCaseReportId > 0 ? (
                    <>
                      <div className="row">
                        <div className="text-center">
                          {isOnDemandCallFeatureEnabled && (
                            <button
                              onClick={onSeeADentistNowButtonClicked}
                              className="btn btn-secondary btn-lg text-nowrap px-5 mx-3 mt-2 mt-md-0"
                            >
                              {getSeeADentistNowButtonText()}
                            </button>
                          )}
                          <button
                            onClick={onPrintBtnClicked}
                            className="btn btn-primary btn-lg text-nowrap px-5 mx-3 mt-2 mt-md-0"
                          >
                            Print or Share
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {activeTab === "Whiteness Score" && (
                <WhitenessScorePage
                  patientId={patientId}
                  onGraphPointClicked={onGraphPointClicked}
                  smartScanId={getSmartScanId()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellnessScorePage;
